<template>
  <div class="text-center">
    <v-dialog v-model="show" max-width="300px">
      <v-card>
        <v-card-title>Integration</v-card-title>

        <v-card-text>
          <p>Du kannst die Daten dynamisch in deinen Kalender integrieren.</p>

          <v-list nav>
            <v-list-item>
              <v-btn color="primary" href="webcal://jazznuts.krueckl.de/probe" block> Proben </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn color="primary" href="webcal://jazznuts.krueckl.de/orga" block> Orga </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn color="primary" href="webcal://jazznuts.krueckl.de/schauspiel" block> Schauspiel </v-btn>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
};
</script>  