<template>
  <div class="text-center">
    <v-dialog v-model="show" max-width="300px">
      <v-card>
        <v-card-title>Integration</v-card-title>

        <v-card-text>
          <p>
            Die Kalender App in Android kann aktuell keine dynamischen Imports direkt durchführen.
          </p>
          <p>
            Möchtest du die Termine dynamisch in deinem Kalender, musst du die Seite auf einem PC öffnen und sie dort zu deinem Google Kalender hinzufügen.
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="show = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
};
</script>  