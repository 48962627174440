<template>
  <div class="text-center">
    <v-dialog v-model="show" max-width="300px">
      <v-card>
        <v-card-title>Push Nachrichten</v-card-title>

        <v-card-text>
          <p>Möchtest du automatisch bei Terminen benachrichtigt werden?</p>
          <v-btn
            color="primary"
            @click="registerPush()"
            block
            class="mb-3"
            :disabled="isSubscribed != false"
          >
            Aktivieren
          </v-btn>
          <v-btn
            color="primary"
            @click="disablePush()"
            block
            :disabled="isSubscribed != true"
          >
            Deaktivieren
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="close()"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { registerPush, disablePush } from "../push";

export default {
  data() {
    return {
      show: false,
      worker: undefined,
      calendars: null,
      subscription: null,
      isSubscribed: undefined,
    };
  },
  methods: {
    registerPush() {
      this.show = false;
      registerPush(this.worker, this.calendars);
      localStorage.setItem("push", "true");
      location.reload();
    },

    disablePush() {
      this.show = false;
      disablePush(this.subscription);
      localStorage.setItem("push", "false");
      location.reload();
    },

    close() {
      this.show = false;
    },
  },
};
</script>  