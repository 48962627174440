<template>
  <div class="pa-5 max-height">
    <v-sheet height="100%">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="filterEvents"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        @click:event="showEvent"
        v-touch="{
          left: () => $refs.calendar.next(),
          right: () => $refs.calendar.prev(),
        }"
        @change="changeDate"
        class="calclass"
      ></v-calendar>

      <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-x
      >
        <v-card outlined>
          <v-toolbar :color="selectedEvent.color" dark>
            <v-toolbar-title
              v-html="selectedEvent.name"
              class="mr-auto"
            ></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="selectedOpen = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <p class="font-weight-bold mb-1">
              {{
                new Date(selectedEvent.start).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}
              -
              {{
                new Date(selectedEvent.end).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })
              }}
            </p>

            <p v-if="selectedEvent.location" class="mb-1">
              <b>Ort:</b> <span v-html="selectedEvent.location"></span>
            </p>

            <p v-if="selectedEvent.description" class="mb-1">
              <span v-html="selectedEvent.description"></span>
            </p>
          </v-card-text>
          <!-- <v-card-actions>
            <v-btn text color="secondary" @click="selectedOpen = false">
              Close
            </v-btn>
          </v-card-actions> -->
        </v-card>
      </v-menu>
    </v-sheet>

    <v-sheet tile height="54" class="d-flex mt-5">
      <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-select
        v-model="cal"
        :items="cals"
        item-value="text"
        dense
        outlined
        hide-details
        return-object
        multiple
        class="ma-2"
        label="Kalender"
        @change="saveCalConfig"
      ></v-select>
      <v-btn icon class="ma-2" @click="$refs.calendar.next()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
  </div>
</template>


<script>
Date.prototype.getWeek = function () {
  // Create a copy of this date object
  var target = new Date(this.valueOf());

  // ISO week date weeks start on Monday, so correct the day number
  var dayNr = (this.getDay() + 6) % 7;

  // ISO 8601 states that week 1 is the week with the first Thursday of that year
  // Set the target date to the Thursday in the target week
  target.setDate(target.getDate() - dayNr + 3);

  // Store the millisecond value of the target date
  var firstThursday = target.valueOf();

  // Set the target to the first Thursday of the year
  // First, set the target to January 1st
  target.setMonth(0, 1);

  // Not a Thursday? Correct the date to the next Thursday
  if (target.getDay() !== 4) {
    target.setMonth(0, 1 + ((4 - target.getDay() + 7) % 7));
  }

  // The week number is the number of weeks between the first Thursday of the year
  // and the Thursday in the target week (604800000 = 7 * 24 * 3600 * 1000)
  return 1 + Math.ceil((firstThursday - target) / 604800000);
};

export default {
  data: () => ({
    cal: [
      { value: "probe", text: "Probe" },
      { value: "orga", text: "Orga" },
      { value: "spiel", text: "Schauspiel" },
    ],
    cals: [
      { value: "probe", text: "Probe" },
      { value: "orga", text: "Orga" },
      { value: "spiel", text: "Schauspiel" },
    ],
    type: "month",
    types: ["week", "month"],
    mode: "stack",
    weekday: [1, 2, 3, 4, 5, 6, 0],
    value: "",
    events: undefined,

    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,

    title: "J🌰 Kalender",
  }),

  computed: {
    filterEvents() {
      if (this.events === undefined || this.events === null) {
        return [];
      }

      const selected = this.cal.map((e) => e.value);

      const filtered = this.events
        .filter((e) => selected.includes(e.type))
        .map((e) => {
          let new_e = e;
          new_e.name = new_e.title;
          new_e.color = { probe: "green", orga: "blue", spiel: "orange" }[
            e.type
          ];
          return new_e;
        });

      return filtered;
    },
  },
  methods: {
    changeDate(date) {

      const names = [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ];

      if (this.type === "month") {
        this.title = names[date.start.month - 1] + " " + date.start.year;
      } else {
        let start_date = new Date(date.start.date);
        this.title = "CW " + start_date.getWeek() + " " + date.start.year;

        if (
          document.getElementsByClassName("v-calendar-daily__scroll-area").length == 0
        ) {

          setTimeout(() => {
            var objDiv = document.getElementsByClassName(
              "v-calendar-daily__scroll-area"
            );
            if (objDiv.length > 0) {
              let el = objDiv[0];
              
              el.scrollTop = el.scrollHeight;
            }
          }, 200);
        }
      }

      this.$emit("changeTitle", this.title);
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() =>
          requestAnimationFrame(() => (this.selectedOpen = true))
        );
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    saveCalConfig() {
      localStorage.setItem("cal", JSON.stringify(this.cal));

      this.$emit("changeCalendars", this.title);
    },

    updateEvents() {
      fetch("/json")
        .then((r) => r.json())
        .then((json) => {
          this.events = json.events;
          localStorage.setItem("events", JSON.stringify(json.events));
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    //load events from local Storage first
    this.events = JSON.parse(localStorage.getItem("events"));

    try {
      let old_cal = JSON.parse(localStorage.getItem("cal"));
      if (old_cal.length > 0) {
        this.cal = old_cal;
      }
    } catch {
      console.log("no old cal config");
    }

    try {
      let old_type = localStorage.getItem("type");
      
      if (old_type) {
        this.type = old_type;
      }
    } catch {
      console.log("no old type config");
    }

    this.updateEvents();
  },
};
</script>


<style scoped>
.max-height {
  height: calc(100vh - 140px);
}

.calclass {
  height: calc(100vh - 180px);
}
</style>

<style>
.calclass .v-btn--round {
  border: 3px solid #ce5454;
}
</style>
