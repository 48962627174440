
<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app>
      <v-list nav>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              J🌰 Kalender
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link href="/">
          <v-list-item-icon>
            <v-icon>mdi-reload</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Daten neu laden</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link @click="$refs.downloadDialog.show = true">
          <v-list-item-icon>
            <v-icon>mdi-download</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Download</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          @click="$refs.appleDialog.show = true"
          v-if="isApple()"
        >
          <v-list-item-icon>
            <v-icon>mdi-apple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Integration</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          @click="$refs.googleDialog.show = true"
          v-if="!isMobile() || isApple()"
        >
          <v-list-item-icon>
            <v-icon>mdi-google</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Integration</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          @click="$refs.androidDialog.show = true"
          v-if="isMobile() && !isApple()"
        >
          <v-list-item-icon>
            <v-icon>mdi-android</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Integration</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-select
            v-model="type"
            :items="$refs.cal != undefined ? $refs.cal.types : []"
            dense
            outlined
            hide-details
            return-object
            class="ma-2"
            label="Ansicht"
            @change="changeCalType"
          ></v-select>
        </v-list-item>

        <v-list-item v-if="isSubscribed()" link @click="openPushDialog">
          <v-list-item-icon>
            <v-icon>mdi-bell</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Nachrichten aktiv</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canSubscribe()" link @click="openPushDialog">
          <v-list-item-icon>
            <v-icon>mdi-bell</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Nachrichten aktivieren</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>{{ appTitle }}</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <MainCalendar
        ref="cal"
        @changeTitle="updateTitle"
        @changeCalendars="changeCalendars"
      />
    </v-main>
    <DownloadDialog ref="downloadDialog"></DownloadDialog>
    <AppleImportDialog ref="appleDialog"></AppleImportDialog>
    <GoogleImportDialog ref="googleDialog"></GoogleImportDialog>
    <AndroidImportDialog ref="androidDialog"></AndroidImportDialog>
    <UpdateDialog ref="updateDialog"></UpdateDialog>
    <RegisterPushDialog ref="pushDialog"></RegisterPushDialog>
  </v-app>
</template>


<script>
import MainCalendar from "./components/MainCalendar";
import DownloadDialog from "./components/DownloadDialog.vue";
import AppleImportDialog from "./components/AppleImportDialog.vue";
import GoogleImportDialog from "./components/GoogleImportDialog.vue";
import AndroidImportDialog from "./components/AndroidImportDialog.vue";
import UpdateDialog from "./components/UpdateDialog.vue";
import RegisterPushDialog from "./components/RegisterPushDialog.vue";

import { updateSubscriptionOnServer } from "./push";

export default {
  name: "App",

  components: {
    MainCalendar,
    DownloadDialog,
    AppleImportDialog,
    GoogleImportDialog,
    AndroidImportDialog,
    UpdateDialog,
    RegisterPushDialog,
  },

  data: () => ({
    drawer: null,
    refreshing: false,
    appTitle: "J🌰 Kalender",
    type: "",
    subscribed: undefined,
    subscription: null,
    worker: null,
  }),
  methods: {
    isMobile() {
      return navigator.userAgentData.mobile;
    },
    isApple() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },

    isSubscribed() {
      return this.subscribed == true;
    },

    canSubscribe() {
      return this.subscribed == false;
    },

    refreshPossible(e) {
      if (e.detail) {
        this.$refs.updateDialog.show = true;
        this.$refs.updateDialog.worker = e.detail;
      } else {
        console.warn("No worker data found!");
      }
    },
    openPushDialog() {
      this.$refs.pushDialog.show = true;
      this.$refs.pushDialog.subscription = this.subscription;
      this.$refs.pushDialog.worker = this.worker;
      this.$refs.pushDialog.isSubscribed = this.isSubscribed();
      this.$refs.pushDialog.calendars = this.$refs.cal.cal.map(
        (el) => el.value
      );
    },
    pushPossible(e) {
      let that = this;

      if ("PushManager" in window) {
        if (e.detail) {
          this.worker = e.detail;
          e.detail.pushManager.getSubscription().then(function (subscription) {
            that.subscription = subscription;
            that.subscribed = !(subscription === null);

            let newly = false;
            try {
              newly = localStorage.getItem("push") === null;
            } catch {
              newly = true;
            }

            if (!that.subscribed && newly) {
              that.$refs.pushDialog.show = true;
              that.$refs.pushDialog.worker = e.detail;
              that.$refs.pushDialog.isSubscribed = false;
              that.$refs.pushDialog.calendars = that.$refs.cal.cal.map(
                (el) => el.value
              );
            } else {
              updateSubscriptionOnServer(
                subscription,
                that.$refs.cal.cal.map((el) => el.value)
              );
            }
          });
        } else {
          console.warn("No worker data found!");
        }
      }
    },

    updateTitle(newTitle) {
      this.appTitle = "J🌰 " + newTitle;
    },
    changeCalType() {
      this.$refs.cal.type = this.type;
      localStorage.setItem("type", this.type);
    },
    changeCalendars() {
      updateSubscriptionOnServer(
        this.subscription,
        this.$refs.cal.cal.map((el) => el.value)
      );
    },
  },

  created() {
    try {
      let old_type = localStorage.getItem("type");

      if (old_type) {
        this.type = old_type;
      }
    } catch {
      console.log("no old type config");
    }

    //Listener for the push stuff
    document.addEventListener("swRegistered", this.pushPossible);

    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener("swUpdated", this.refreshPossible, {
      once: true,
    });
    // Refresh all open app tabs when a new service worker is installed.
    navigator.serviceWorker &&
      navigator.serviceWorker.addEventListener(
        //triggered by registration.claim
        "controllerchange",
        () => {
          if (this.refreshing) return;
          this.refreshing = true;
          console.log("controllerchange triggered, -> auto refresh!!");
          window.location.reload();
        }
      );
  },
};
</script>


<style>

html {
  overflow-y: auto;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.v-toolbar {
  background: #28a745 !important;
}

.v-toolbar__title {
  color: #fff;
  font-weight: 400;
}
</style>
