
const applicationServerPublicKey =
    "BG9gMXV5kgnDewycBgvFdsH_b3avt5w-cKA726LZ1mDkQzFweZWFW_jQrJJFVB5WZ3qPrqUQ6M0BgvGj1v-yBV4";

function registerPush(worker, calendars) {
    const applicationServerKey = urlB64ToUint8Array(
        applicationServerPublicKey
    );
    worker.pushManager
        .subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey,
        })
        .then(function (subscription) {
            updateSubscriptionOnServer(subscription, calendars);
        })
        .catch(function (error) {
            console.error("Failed to subscribe the user: ", error);
        });
}

function disablePush(oldsubscription) {
    oldsubscription
        .unsubscribe()
        .then(function () {
            deleteSubscriptionOnServer(oldsubscription);
        })
        .catch(function (error) {
            console.error("Failed to unsubscribe the user: ", error);
        });
}

function urlB64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function updateSubscriptionOnServer(subscription, calendars) {
    if (subscription) {

        let sub_info = {
            ...JSON.parse(JSON.stringify(subscription)),
            "calendars": calendars
        }

        console.log("sub", JSON.stringify(sub_info));

        fetch("/subscribePush", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(sub_info)
        })

    }
}


function deleteSubscriptionOnServer(subscription) {
    if (subscription) {

        let sub_info = {
            ...JSON.parse(JSON.stringify(subscription)),
            "calendars": []
        }

        console.log("delete sub", JSON.stringify(sub_info));

        fetch("/subscribePush", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(sub_info)
        })

    }
}


export { registerPush, updateSubscriptionOnServer, disablePush }